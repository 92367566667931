body{
background-color:#BED0D6;;
font-family: "TheSans", "Noto Sans", sans-serif;
font-size:16px;
}
.portaal-header{
    background-color: #BED0D6;

}
.inhoud{
       width:95%;
}
.content{
    background-color:#E7E7E7;
 
}
.img-rounded{
    border-radius: 16px;
    border-top-radius:16px;
border-right-radius:16px;
border-bottom-radius:16px;
border-left-radius:16px;
}
.assessment-date {
    margin-top: 10px;
    vertical-align: middle;
    }

.assessment-questionnaire{
  /*  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 1.625em;
    line-height: 2em;
    font-weight: lighter;
    margin-bottom: 10px;*/

}
.portaal-footer{
    font-size: smaller;
    margin-top:20px;
    text-align: right;
}
.assessment-action-block {
    line-height: 115px;
    vertical-align: middle;
}
.assessment-action {
    color: #1d71b8;
   
}
.start-button{
    margin-left:-10px;
}
.col-xl-2{
    width:70%;
    background-color:blue;
}
/*.assessment-action::after {
    content: " > ";
}*/
.invullen{
    vertical-align: middle;
    margin-top:10%;
}
.vragenlijst-item{
   
    border-color: #E7E7E7;
    border-width: 1px;
    /*height: 115px;*/
    background-color: #FFFFFF;
    margin-bottom: 15px;
}
.vragenlijst-vraag{
   white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 1.625em;
    line-height: 2em;
    font-weight: lighter;
    margin-bottom: 10px;
}
 hr{
    border-radius: 2px;
    height: 4px;
    /*background-color: #ec008c;*/
    background-color: #B52718;
    margin-top: 0;
    margin-bottom: 0;
    border-color: transparent;
    border-width: 0;
}
a{
    color: #4E4E4E;
}
#flash-message{
    width:50%;
    margin-left:20%;
}
.container.content.inhoud{
    min-height: 25em;
}
@media only screen
and (min-width : 220px)
and (max-width : 990px){ 
 .mobileHide { display: none;}
  .toplegenda{ margin-left:0.013px;}
  .start-button{height: 40px;width: 40px;margin-top: 10px;}
   h2{font-size:1.8rem;margin-top:5px;margin-bottom:5px;}
  h3{font-size:1.2rem;margin-top:5px;margin-bottom:5px;}
   h4{font-size:0.9rem;margin-top:5px;margin-bottom:5px;}
     h5{font-size:0.7rem;margin-top:5px;margin-bottom:5px;}
   .btn{padding:0;}
}
@media only screen
and (min-width : 991px)
{ 
 .mobileOnly { display: none;}
 .toplegenda{ margin-left:4px;}
 .start-button{height: 65px;width: 65px;margin-top: 20px;}
  h4{font-size:1.5rem;margin-top:5px;margin-bottom:5px;}
  .naam-cursist{margin-top:2em;}
}
